module.exports = {
  comm: {
    home: 'হোম', // 新增
    pricing: 'মূল্য নির্ধারণ', // 新增
    blog: 'ভয়েস ক্লোনিং ও API', // 更新
    language: 'ভাষা:',
    select_language: 'ভাষা নির্বাচন করুন',
    txt2voice: 'টেক্সট থেকে ভাষা',
    voice2txt: 'ভাষা থেকে টেক্সট',
    voiceclone: 'ভাষা ক্লোন',
    video2txt: 'ভিডিও থেকে টেক্সট',
    footer_help: 'সাহায্যের প্রয়োজন? আমাদের ইমেল করুন:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'লগ ইন',
    login_desc: 'আপনার টিকটক ভয়েস জেনারেটর অ্যাকাউন্টে অ্যাক্সেস করতে লগইন করুন',
    logout: 'লগ আউট',
  },
  txt2voice: {
    title: 'TikTok ভয়েস জেনারেটর: মজাদার AI TikTok ভাষা তৈরি করুন',
    description: 'মজাদার AI TikTok ভাষা তৈরি করুন এবং বিনামূল্যে ডাউনলোড করুন, যেমন জেসির ভাষা, C3PO ভাষা, ঘোস্টফেস ভাষা...',
    keywords: 'TikTok ভয়েস জেনারেটর, AI TikTok ভাষা, Tik Tok ভয়েস জেনারেটর',
    main_title_p01: 'TikTok ভয়েস জেনারেটর',
    main_title_p02: 'মজাদার TikTok ভাষা তৈরি করুন: জেসির ভাষা, C3PO ভাষা, ঘোস্টফেস ভাষা',
    main_textarea_holder: 'এখানে টেক্সট টাইপ বা পেস্ট করুন',
    main_genvoice: 'তৈরি করুন',
    main_generating: 'তৈরি হচ্ছে',
    main_input_empty: 'অনুগ্রহ করে টেক্সট টাইপ বা পেস্ট করুন',
    daily_usage_limit_msg: '1) আজকের জন্য সর্বাধিক ব্যবহারের সীমা পূর্ণ হয়েছে। অনুগ্রহ করে আগামীকাল আবার আসুন। 2) যদি জরুরি প্রয়োজনে আরও ব্যবহার প্রয়োজন হয়, দয়া করে আমাদের ইমেইল করুন।',
    text_max_prompt1: 'অনুগ্রহ করে শব্দের সংখ্যা সীমিত করুন',
    text_max_prompt2: 'শব্দ বা কম!',
    popup_nologin_title: 'আপনার সাবস্ক্রিপশন প্ল্যান আপগ্রেড করুন',
    popup_nologin_desc: 'প্রিমিয়াম সাবস্ক্রিপশনে আপগ্রেড করে আরও বেশি ভয়েস জেনারেশন সময় আনলক করুন।',
    popup_nologin_btntext: 'সাবস্ক্রিপশন প্ল্যান অন্বেষণ করুন',
    popup_nosub_title: 'আপনার সাবস্ক্রিপশন প্ল্যান আপগ্রেড করুন',
    popup_nosub_desc: 'প্রিমিয়াম সাবস্ক্রিপশনে আপগ্রেড করে আরও বেশি ভয়েস জেনারেশন সময় আনলক করুন।',
    popup_nosub_btntext: 'সাবস্ক্রিপশন প্ল্যান অন্বেষণ করুন',
    popup_sublimit_title: 'সাবস্ক্রিপশন শেষ হয়ে গেছে, অনুগ্রহ করে আপগ্রেড করুন',
    popup_sublimit_desc: 'আমাদের প্রিমিয়াম ফিচার এবং অসীমিত অ্যাক্সেসের সাথে আপনার অভিজ্ঞতা উন্নত করুন।',
    popup_sublimit_btntext: 'আরও কিনতে যান',
  },
  pricing: {
    new_features_alert: "📣 আমরা 🎙️ ভয়েস ক্লোনিং এবং 🤖 API পরিষেবা চালু করেছি পেইড সদস্যদের জন্য! (পেমেন্টের পরে, এই বৈশিষ্ট্যগুলির জন্য সহায়তার জন্য tiktokaivoicetool@gmail.com এ ইমেল করুন)। এছাড়াও, যে কোনও কাস্টমাইজেশন অনুরোধের জন্য আমাদের ইমেল করতে দ্বিধা করবেন না😃", // 新增
    pricing_title: 'ব্যক্তিগত ক্রিয়েটর থেকে বড় কর্পোরেশন পর্যন্ত, আপনার জন্য একটি প্ল্যান আছে।',
    pricing_desc: '100টিরও বেশি প্রাকৃতিক, মানবিক কণ্ঠস্বর। 15টিরও বেশি ভাষা। প্রায় সবার জন্য সর্বনিম্ন মূল্য',
    sub_free_name: 'ফ্রি',
    sub_free_desc: 'সর্বাধুনিক AI অডিও চেষ্টা করতে চান এমন ব্যক্তিদের জন্য',
    sub_free_content: [
      'মাসিক 1000 অক্ষর সীমা',
      'দৈনিক 5 জেনারেশন',
    ],
    sub_starter_name: 'স্টার্টার',
    sub_starter_desc: 'বিশ্বব্যাপী দর্শকদের জন্য প্রিমিয়াম কন্টেন্ট তৈরি করছেন এমন ক্রিয়েটরদের জন্য',
    sub_starter_content: [
      '1 ভয়েস ক্লোন',
      'মাসিক 300,000 অক্ষর সীমা (প্রায় 7 ঘন্টা অডিও)',
      'অসীমিত ডাউনলোড',
      '72 ঘন্টার মধ্যে ইমেল সাপোর্ট',
      'নতুন ভয়েস এবং ফিচারে অগ্রাধিকার অ্যাক্সেস',
    ],
    sub_pro_name: 'প্রো',
    sub_pro_desc: 'তাদের কন্টেন্ট প্রোডাকশন বাড়াচ্ছেন এমন ক্রিয়েটরদের জন্য',
    sub_pro_content: [
      'API অ্যাক্সেস',
      '3 ভয়েস ক্লোন',
      'মাসিক 1,000,000 অক্ষর সীমা (প্রায় 24 ঘন্টা অডিও)',
      'অসীমিত ডাউনলোড',
      '48 ঘন্টার মধ্যে ইমেল সাপোর্ট',
      'নতুন ভয়েস এবং ফিচারে অগ্রাধিকার অ্যাক্সেস',
    ],
    period_year: 'বার্ষিক',
    period_month: 'মাসিক',
    period_month_short: 'মাস',
    billed_year: 'বার্ষিক বিল করা হয়',
    most_popular: 'সবচেয়ে জনপ্রিয়',
    discount_quantity: '$48 সাশ্রয় করুন',
    buy_btntext: 'শুরু করুন',
    pay_succ: 'পেমেন্ট সফল!',
    pay_succ_desc: 'আপনার ক্রয়ের জন্য ধন্যবাদ। লেনদেনটি সফলভাবে প্রক্রিয়া করা হয়েছে।',
    pay_succ_btntext: 'TikTok ভয়েস ব্যবহার শুরু করুন',
    pay_fail: 'পেমেন্ট ব্যর্থ!',
    pay_fail_desc: "দুঃখিত, আমরা আপনার লেনদেন প্রক্রিয়া করতে পারিনি। অনুগ্রহ করে আবার চেষ্টা করুন বা আমাদের সাপোর্ট টিমের সাথে যোগাযোগ করুন (tiktokaivoicetool@gmail.com)।",
    pay_fail_btntext: 'হোমপেজে ফিরে যান',
  },
  setting: {
    setting: 'সেটিংস',
    setting_title: 'এখানে আপনার অ্যাকাউন্ট, ব্যবহার এবং সাবস্ক্রিপশন পরিচালনা করুন।',
    basic_info: 'মৌলিক তথ্য', // 新增，放在 setting_title 下方
    user_name: 'নাম',
    user_email: 'ইমেইল',
    user_account: 'অ্যাকাউন্ট',
    user_subscript: 'বর্তমান প্ল্যান',
    user_usage: 'ব্যবহার',
    manage_subscript: 'সাবস্ক্রিপশন পরিচালনা করুন',
    use_limit_promote: "আপনি আপনার মাসিক 10,000 অক্ষর সীমার মধ্যে 5,000 অক্ষর ব্যবহার করেছেন। আরও ব্যবহার করতে আপনার প্ল্যান আপগ্রেড করুন।",
    not_logged_in: 'আপনি লগইন করেননি', // 新增
    go_to_login: 'লগইন করতে যান >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "টিকটক ভয়েস জেনারেটর",
    subtitle: "আপনার টিকটক ভয়েস জেনারেটর অ্যাকাউন্টে অ্যাক্সেস করতে লগইন করুন",
    alreadyLoggedIn: "আপনি ইতিমধ্যে লগইন করেছেন",
    goToHome: "হোমে যান"
  },
  faq: {
    quest1: 'TikTok ভয়েস জেনারেটর - সেরা AI TikTok ভাষা টুল',
    answer1_1: 'এটি নতুনতম tts TikTok প্রযুক্তি দ্বারা উন্নত করা TikTok AI ভয়েস জেনারেটর টুল, এটি TikTok ভাষা তৈরি করতে পারে যেমন জেসির ভাষা (নারী ভাষা), সিরি ভাষা, ঘোস্টফেস ভাষা, C3PO ভাষা (রোবট ভাষা), গভীর ভাষা (কাহিনী বর্ণনা), স্কাই ভাষা, উষ্ণ ভাষা, বেস্টি ভাষা, হিরো ভাষা (চিরিস ভাষা), সহানুভূতিশীল ভাষা, গম্ভীর ভাষা, জোয়ের ভাষা, স্টিচ ভাষা, স্টর্মট্রুপার ভাষা (স্টার ওয়ার্স), রকেট ভাষা (গার্ডিয়ান্স অফ দ্য গ্যালাক্সি)।',
    answer1_2: 'শীঘ্রই সমর্থিত AI TikTok ভাষা অন্তর্ভুক্ত: ডেরেক ভাষা, ট্রিকস্টার ভাষা, অস্টিন বাটলার ভাষা, সংবাদ প্রতিবেদনকারী ভাষা, আদম ভাষা, কুকুর ভাষা, মাইলি সাইরাস ভাষা, আলেক্সা ভাষা, এলিয়েন ভাষা, প্রাণী ভাষা, বাচ্চার ভাষা, কম্পিউটার ভাষা, চিপমাঙ্ক ভাষা, ইকো ভাষা, npr ভাষা।',
    answer1_3: 'এর পাশাপাশি, TikTok ভয়েস জেনারেটর টুল আরও অনেক ভাষা সমর্থন করে, যেমন চীনা, জাপানি, কোরিয়ান, ভিয়েতনামি, থাই, হিন্দি, ফারসি, রুশ, জার্মান, ফরাসি, রোমানিয়ান, চেক, স্প্যানিশ, পর্তুগিজ, বাংলা, ইতালিয়ান, আরবি, উর্দু, প্রথাগত চীনা এবং মালয় ভাষা।',
    answer1_4: 'যদি আপনার জরুরীভাবে নির্দিষ্ট ভাষার প্রয়োজন হয়, অনুগ্রহ করে আমাকে ইমেল করুন।',

quest2: 'TikTok ভয়েস জেনারেটরের সুবিধা কি?',
    answer2_1: '- TikTok ভয়েস জেনারেটর টুল বিভিন্ন ধরনের ভাষা তৈরি করতে পারে, যা প্রায়শই TikTok ভিডিওতে ব্যবহার করা হয়।',
    answer2_2: '- এটি সর্বশেষ AI ভয়েস জেনারেটর যা মানুষের মতো tiktok tts ভাষা তৈরি করতে পারে।',
    answer2_3: '- এটি পিসিতে ভিডিও সম্পাদনার জন্য আরও সুবিধাজনক।',
    answer2_4: '- আপনি কিছু ভাষা ব্যবহার করতে পারেন যা বর্তমানে TikTok অ্যাপ tts এ পাওয়া যায় না।',

quest3: 'TikTok ভয়েস জেনারেটর ব্যবহার করার উপায় কি?',
    answer3_1: 'TikTok ভয়েস জেনারেটর ব্যবহার করা খুব সহজ:',
    answer3_2: '- ভাষা এবং ভাষার উচ্চারণ নির্বাচন করুন।',
    answer3_3: '- ভাষায় রূপান্তরিত করতে টেক্সটটি ইনপুট বক্সে টাইপ করুন।',
    answer3_4: '- তৈরি করুন বোতাম চাপুন এবং কয়েক সেকেন্ড অপেক্ষা করুন।',
    answer3_5: '- TikTok AI ভাষা প্লে করুন বা ডাউনলোড করুন।',

quest4: 'সবচেয়ে বিখ্যাত এবং মজাদার TikTok ভাষা কি কি?',
    answer4_1: 'সবচেয়ে বিখ্যাত পুরুষ TikTok ভাষা হল গভীর ভাষা, যা আনুষ্ঠানিকভাবে কাহিনী বর্ণনা বলা হয়।',
    answer4_2: 'সবচেয়ে মজাদার TikTok ভাষার জন্য, আমি ব্যক্তিগতভাবে ঘোস্টফেস ভাষা এবং C3PO ভাষা সুপারিশ করি। এই ভাষাগুলি প্রায়শই মজাদার TikTok ভিডিওর জন্য ডাবিং করার জন্য ব্যবহার করা হয়, কারণ এগুলি সহজে চিন্হিত করা যায় এবং দর্শকদের দ্রুত আকর্ষণ করতে পারে।',

quest5: 'সবচেয়ে বিরক্তিকর TikTok ভাষা কি কি?',
    answer5_1: 'আমি বিশ্বাস করি যে প্রকৃতপক্ষে কোনও "বিরক্তিকর" TikTok ভাষা নেই।',
    answer5_2: 'এটি আরও বেশি কনটেক্সট এবং বিষয়বস্তু উপর নির্ভর করে যা ভাষাটি ব্যবহার করা হচ্ছে যা কিছু লোককে অসুবিধাজনক করতে পারে। উদাহরণস্বরূপ, কিছু লোক জেসির ভাষা বিরক্তিকর মনে করতে পারে কারণ এটি একটি নির্দিষ্ট সময়ের মধ্যে TikTok এ খুব ঘন ঘন উপস্থিত হয়েছে। তবে, এর সুর এবং গুণমানের কারণে, এটি প্রকৃতপক্ষে একটি দুর্দান্ত TikTok ভাষা প্রভাব। এছাড়াও, এর পেছনের TikTok ভয়েস অভিনেত্রী অত্যন্ত পেশাদার এবং তিনি নিজেও TikTok এ তার ভিডিও পোস্ট করেন।',
    answer5_3: 'তাই, আমি প্রস্তাব করি যে সবাই কিছু উদ্বেগ এবং পূর্বধারণা পাশে রেখে, এই টুল থেকে ভাষাগুলি কয়েকবার শুনুন এবং ধৈর্য সহকারে আপনার জন্য সবচেয়ে ভাল TikTok ভাষা প্রভাবটি খুঁজে বের করুন।',

quest6: 'TikTok ভয়েস জেনারেটর ব্যবহার করে কিভাবে জেসির ভাষা তৈরি করবেন?',
    answer6_1: '- TikTok ভয়েস জেনারেটর টুলের প্রথম ড্রপডাউন মেনুতে, English (US) নির্বাচন করুন, তারপর অন্য ড্রপডাউন মেনুতে, জেসির ভাষা (নারী ভাষা) নির্বাচন করুন।',
    answer6_2: '- ইনপুট বক্সে টেক্সট টাইপ করুন এবং তৈরি করুন বোতাম ক্লিক করুন।',
    answer6_3: '- কয়েক সেকেন্ড বা দশ সেকেন্ড অপেক্ষা করুন, আপনি AI ভাষা শুনবেন। অপারেশন বারে, নিচের দিকে তীর চিহ্নযুক্ত বোতামটি খুঁজে বের করুন এবং ডাউনলোড করার জন্য ক্লিক করুন।',

quest7: 'TikTok ভয়েস জেনারেটর ব্যবহার করে কিভাবে C3PO ভাষা তৈরি করবেন?',
    answer7_1: '- TikTok ভয়েস জেনারেটর টুলের প্রথম ড্রপডাউন মেনুতে, English (US) নির্বাচন করুন, তারপর অন্য ড্রপডাউন মেনুতে, C3PO ভাষা (রোবট ভাষা) নির্বাচন করুন।',
    answer7_2: '- ইনপুট বক্সে টেক্সট টাইপ করুন এবং তৈরি করুন বোতাম ক্লিক করুন।',
    answer7_3: '- কয়েক সেকেন্ড বা দশ সেকেন্ড অপেক্ষা করুন, আপনি AI ভাষা শুনবেন। অপারেশন বারে, নিচের দিকে তীর চিহ্নযুক্ত বোতামটি খুঁজে বের করুন এবং ডাউনলোড করার জন্য ক্লিক করুন।',

quest8: 'TikTok ভয়েস জেনারেটর ব্যবহার করে কিভাবে ঘোস্টফেস ভাষা তৈরি করবেন?',
    answer8_1: '- TikTok ভয়েস জেনারেটর টুলের প্রথম ড্রপডাউন মেনুতে, English (US) নির্বাচন করুন, তারপর অন্য ড্রপডাউন মেনুতে, ঘোস্টফেস ভাষা নির্বাচন করুন।',
    answer8_2: '- ইনপুট বক্সে টেক্সট টাইপ করুন এবং তৈরি করুন বোতাম ক্লিক করুন।',
    answer8_3: '- কয়েক সেকেন্ড বা দশ সেকেন্ড অপেক্ষা করুন, আপনি AI ভাষা শুনবেন। অপারেশন বারে, নিচের দিকে তীর চিহ্নযুক্ত বোতামটি খুঁজে বের করুন এবং ডাউনলোড করার জন্য ক্লিক করুন।',

quest9: 'TikTok ভয়েস জেনারেটর ব্যবহার করে কিভাবে সিরি ভাষা তৈরি করবেন?',
    answer9_1: '- TikTok ভয়েস জেনারেটর টুলের প্রথম ড্রপডাউন মেনুতে, English (US) নির্বাচন করুন, তারপর অন্য ড্রপডাউন মেনুতে, সিরি ভাষা নির্বাচন করুন।',
    answer9_2: '- ইনপুট বক্সে টেক্সট টাইপ করুন এবং তৈরি করুন বোতাম ক্লিক করুন।',
    answer9_3: '- কয়েক সেকেন্ড বা দশ সেকেন্ড অপেক্ষা করুন, আপনি AI ভাষা শুনবেন। অপারেশন বারে, নিচের দিকে তীর চিহ্নযুক্ত বোতামটি খুঁজে বের করুন এবং ডাউনলোড করার জন্য ক্লিক করুন।',

quest10: 'কিভাবে TikTok ভিডিওতে TikTok ভাষার প্রভাব যোগ করবেন?',
    answer10_1: 'যদি আপনি জানতে চান যে কিভাবে TikTok এ সরকারি TikTok ভাষাগুলি ব্যবহার করবেন, আমি শীঘ্রই এই বিষয়ের উপর একটি নিবন্ধ লিখব।',
    answer10_2: 'এখানে, আমি ব্যাখ্যা করব কিভাবে TikTok ভয়েস জেনারেটর থেকে ভাষা তৈরি করার পরে TikTok এ আপনার ভাষা পোস্ট করবেন।',
    answer10_3: '1. যদি আপনি আপনার পিসিতে TikTok ভাষা তৈরি করেছেন এবং এটি TikTok বা আপনার ফোনে অ্যান্য কোনও ভিডিও এডিটিং অ্যাপে আপলোড করতে চান, আপনাকে ভাষা ফাইলটি আপনার ফোনে স্থানান্তর করতে হবে। আইফোনের জন্য, আপনি এয়ারড্রপ ব্যবহার করে স্থানান্তর করতে পারেন। অ্যান্ড্রয়েড ফোনের জন্য, আমি এই প্রক্রিয়াটি খুব বেশি পরিচিত নই, তবে আপনি এটি করার জন্য নিশ্চিতভাবে পদ্ধতি এবং সরঞ্জাম খুঁজে পাবেন।',
    answer10_4: '2. একবার ভাষা ফাইলটি আপনার ফোনে স্থানান্তরিত হলে, আপনি TikTok খুলতে পারেন:',
    answer10_5: '- ইন্টারফেসের নীচে "+" বোতামে ক্লিক করুন, আপনার ফোনে ভিডিও নির্বাচন করুন।',
    answer10_6: '- ভিডিও আপলোড করার পরে, স্ক্রিনের ডান দিকে একটি বর্গাকার আইকন খুঁজুন এবং এডিটিং পৃষ্ঠায় প্রবেশ করুন।',
    answer10_7: '- পৃষ্ঠার নীচে "ভাষা যোগ করুন" বোতামটি খুঁজুন এবং সদ্য স্থানান্তরিত ভাষা ফাইলটি নির্বাচন করতে ক্লিক করুন।',
    answer10_8: '- এই ইন্টারফেসে, আপনি ভিডিওতে কিছু সমন্বয় করতে পারেন, তারপর এটি পোস্ট করতে ডানদিকে উপরের কোণে বোতামে ক্লিক করুন।',

quest11: 'TikTok Voice কি বিনামূল্যে?',
    answer11: 'হ্যাঁ, এটি একটি বিনামূল্যের TikTok AI ভয়েস টেক্সট থেকে ভাষা জেনারেটর টুল।',

quest12: 'কোথায় আমি আমার TikTok ভিডিওতে জনপ্রিয় TikTok ভাষা তৈরি করতে পারি?',
    answer12: 'আপনি https://tiktokvoice.net/ এ যেে পারেন আপনার ভিডিওতে AI TikTok ভষা যোগ করার জন্য।'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}